import axios from '@/common/axios'
import qs from 'qs'

export function addPlan (data) {
  return axios({
    method: 'post',
    url: '/plan/add',
    data: qs.stringify(data)
  })
}

export function deletePlan (id) {
  return axios({
    method: 'delete',
    url: '/plan/delete/' + id
  })
}

export function updatePlan (data) {
  return axios({
    method: 'put',
    url: '/plan/update',
    data: qs.stringify(data)
  })
}

export function updatePlanAudit (data) {
  return axios({
    method: 'put',
    url: '/plan/audit/update',
    data: qs.stringify(data)
  })
}

export function updatePlanApprove (data) {
  return axios({
    method: 'put',
    url: '/plan/approve/update',
    data: qs.stringify(data)
  })
}

export function selectPlanInfo (id) {
  return axios({
    method: 'get',
    url: '/plan/info/' + id
  })
}

export function selectPlanList (query) {
  return axios({
    method: 'get',
    url: '/plan/list',
    params: query
  })
}
