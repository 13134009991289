<template>
  <div id="plan">
    <el-dialog
      :title="planFormTitle"
      width="1500px"
      :visible.sync="planDialogVisible"
      :close-on-click-modal="false"
      @close="planDialogClose"
    >
      <el-form
        ref="planFormRef"
        :model="planForm"
        :rules="planFormRules"
        inline
      >
        <el-form-item label="采购计划时间" prop="planningTime">
          <el-date-picker
            v-model="planForm.planningTime"
            placeholder="请选择采购计划时间"
            value-format="yyyy-MM-dd"
            :readonly="planFormTitle === '采购计划详情'"
            :disabled="checkRole(['GUANDAI']) || checkRole(['ZJL'])"
          />
        </el-form-item>
        <el-form-item label="审核结论" prop="auditResult">
          <el-input
            v-model="planForm.auditResult"
            placeholder="请输入审核结论"
            clearable
            :readonly="planFormTitle === '采购计划详情'"
            :disabled="!checkRole(['GUANDAI'])"
          />
        </el-form-item>
        <el-form-item label="批准" prop="approvalResult">
          <el-radio-group v-model="planForm.approvalResult" :disabled="!checkRole(['ZJL'])">
            <el-radio :label="1">
              同意
            </el-radio>
            <el-radio :label="2">
              不同意
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="原因" prop="reason">
          <el-input
            v-model="planForm.reason"
            placeholder="请输入原因"
            clearable
            :readonly="planFormTitle === '采购计划详情'"
            :disabled="!checkRole(['ZJL'])"
          />
        </el-form-item>
      </el-form>
      <vxe-toolbar v-if="planFormTitle !== '采购计划详情' && !checkRole(['GUANDAI']) && !checkRole(['ZJL'])">
        <template #buttons>
          <vxe-button @click="insertRowEvent()">
            新增
          </vxe-button>
        </template>
      </vxe-toolbar>
      <vxe-table
        ref="xTable"
        border
        resizable
        show-overflow
        keep-source
        show-footer
        :data="planDetailList"
        :edit-rules="validRules"
        :edit-config="{trigger: 'manual', mode: 'row'}"
        :footer-method="footerMethod"
        @edit-actived="editActivedEvent"
      >
        <vxe-table-column type="seq" width="50" />
        <vxe-table-column
          field="productId"
          title="物料名称"
          width="150"
          :edit-render="{
            name: '$select',
            props: {clearable: true},
            options: productList,
            optionProps: {
              label: 'name',
              value: 'id'
            },
            events: {
              change: productChange
            }
          }"
        />
        <vxe-table-column
          field="spec"
          title="规格/型号"
          width="100"
          :edit-render="{name: '$input', props: {readonly: true}}"
        />
        <vxe-table-column
          field="unit"
          title="单位"
          width="100"
          :edit-render="{name: '$input', props: {readonly: true}}"
        />
        <vxe-table-column
          field="inventory"
          title="库存量"
          width="100"
          :edit-render="{name: '$input', props: {readonly: true}}"
        />
        <vxe-table-column
          field="unitPrice"
          title="单价"
          width="100"
          :edit-render="{name: '$input', props: {type: 'number', clearable: true}}"
        />
        <vxe-table-column
          field="demand"
          title="需求量"
          width="100"
          :edit-render="{name: '$input', props: {type: 'number', clearable: true}}"
        />
        <vxe-table-column
          field="plannedPurchases"
          title="计划采购数"
          width="120"
          :edit-render="{name: '$input', props: {type: 'number', clearable: true}}"
        />
        <vxe-table-column
          field="plannedArrivalTime"
          title="计划到货时间"
          width="150"
          :edit-render="{name: '$input', props: {type: 'date', clearable: true}}"
        />
        <vxe-table-column
          field="remarks"
          title="备注"
          :edit-render="{name: '$input', props: {clearable: true}}"
        />
        <vxe-table-column
          field="supplierId"
          title="供应商"
          :edit-render="{
            name: '$select',
            props: {clearable: true},
            options: supplierList,
            optionProps: {
              label: 'supplierName',
              value: 'id'
            },
            events: {
              change: supplierChange
            }
          }"
          :formatter="formatSupplierLabel"
        />
        <vxe-table-column
          v-if="planFormTitle !== '采购计划详情' && !checkRole(['GUANDAI']) && !checkRole(['ZJL'])"
          title="操作"
          width="150"
        >
          <template #default="{ row }">
            <template v-if="$refs.xTable.isActiveByRow(row)">
              <vxe-button @click="saveRowEvent(row)">
                保存
              </vxe-button>
              <vxe-button @click="cancelRowEvent(row)">
                取消
              </vxe-button>
            </template>
            <template v-else>
              <vxe-button @click="editRowEvent(row)">
                编辑
              </vxe-button>
              <vxe-button @click="removeRowEvent(row)">
                删除
              </vxe-button>
            </template>
          </template>
        </vxe-table-column>
      </vxe-table>
      <div slot="footer">
        <el-button @click="planDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="planFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="名称">
        <el-input v-model="searchForm.name" placeholder="请输入名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="planPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="planningTime" label="采购计划时间" />
      <el-table-column prop="editor" label="编制人" />
      <el-table-column prop="budget" label="预算" />
      <el-table-column prop="reviewer" label="审核人" />
      <el-table-column prop="auditResult" label="审核结论" />
      <el-table-column prop="approver" label="批准人" />
      <el-table-column label="批准">
        <template slot-scope="scope">
          <span v-if="scope.row.approvalResult === 1">同意</span>
          <span v-if="scope.row.approvalResult === 2">不同意</span>
        </template>
      </el-table-column>
      <el-table-column prop="reason" label="原因" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <!-- <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button> -->
          <el-button
            v-if="scope.row.approvalResult !== 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="planPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import XEUtils from 'xe-utils'
import {
  addPlan,
  deletePlan,
  updatePlan,
  updatePlanAudit,
  updatePlanApprove,
  selectPlanInfo,
  selectPlanList
} from '@/api/purchase/plan'
import { selectProductList } from '@/api/purchase/product'
import { selectSupplierByProductId } from '@/api/purchase/supplier'

export default {
  data () {
    return {
      planDialogVisible: false,
      planFormTitle: '',
      planForm: {
        id: '',
        planningTime: '',
        budget: '',
        planDetailJson: '',
        auditResult: '',
        approvalResult: '',
        reason: ''
      },
      planFormRules: {
        planningTime: [
          {
            required: true,
            message: '采购计划时间不能为空',
            trigger: ['blur', 'change']
          }
        ]
      },
      planPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        name: ''
      },
      productList: [],
      supplierList: [],
      supplierListCache: [],
      planDetailList: [],
      validRules: {
        productId: [
          { required: true, message: '物料名称必选' }
        ],
        unitPrice: [
          { required: true, message: '单价必填' }
        ],
        plannedPurchases: [
          { required: true, message: '计划采购数必填' }
        ]
      }
    }
  },
  created () {
    selectProductList().then(res => {
      this.productList = res.list
    })
    selectPlanList(this.searchForm).then(res => {
      this.planPage = res
    })
  },
  methods: {
    planDialogClose () {
      this.$refs.planFormRef.resetFields()
      this.planDetailList = []
    },
    planFormSubmit () {
      if (this.planFormTitle === '采购计划详情') {
        this.planDialogVisible = false
        return
      }
      this.$refs.planFormRef.validate(async valid => {
        if (valid) {
          this.planForm.planDetailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
          if (this.planFormTitle === '新增采购计划') {
            await addPlan(this.planForm)
          } else if (this.planFormTitle === '修改采购计划') {
            if (this.checkRole(['GUANDAI'])) {
              await updatePlanAudit(this.planForm)
            } else if (this.checkRole(['ZJL'])) {
              await updatePlanApprove(this.planForm)
            } else {
              await updatePlan(this.planForm)
            }
          }
          this.planPage = await selectPlanList(this.searchForm)
          this.planDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.planFormTitle = '新增采购计划'
      this.planDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deletePlan(row.id)
        if (this.planPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.planPage = await selectPlanList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.planFormTitle = '修改采购计划'
      this.planDialogVisible = true
      selectPlanInfo(row.id).then(async res => {
        this.planForm.id = res.id
        this.planForm.planningTime = res.planningTime
        this.planForm.budget = res.budget
        this.planForm.type = res.type
        this.planForm.auditResult = res.auditResult
        this.planForm.approvalResult = res.approvalResult
        this.planForm.reason = res.reason
        for (let planDetail of res.detailList) {
          let productId = planDetail.productId
          let item = this.supplierListCache.find(item => item.productId === productId)
          if (!item) {
            let supplierList = await selectSupplierByProductId(productId)
            this.supplierListCache.push({ productId, supplierList })
          }
        }
        this.planDetailList = res.detailList
      })
    },
    handleInfo (index, row) {
      this.planFormTitle = '采购计划详情'
      this.planDialogVisible = true
      selectPlanInfo(row.id).then(async res => {
        this.planForm.id = res.id
        this.planForm.planningTime = res.planningTime
        this.planForm.budget = res.budget
        this.planForm.type = res.type
        this.planForm.auditResult = res.auditResult
        this.planForm.approvalResult = res.approvalResult
        this.planForm.reason = res.reason
        for (let planDetail of res.detailList) {
          let productId = planDetail.productId
          let item = this.supplierListCache.find(item => item.productId === productId)
          if (!item) {
            let supplierList = await selectSupplierByProductId(productId)
            this.supplierListCache.push({ productId, supplierList })
          }
        }
        this.planDetailList = res.detailList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectPlanList(this.searchForm).then(res => {
        this.planPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectPlanList(this.searchForm).then(res => {
        this.planPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectPlanList(this.searchForm).then(res => {
        this.planPage = res
      })
    },
    async insertRowEvent (row) {
      let { row: newRow } = await this.$refs.xTable.insertAt({}, -1)
      await this.$refs.xTable.setActiveRow(newRow)
    },
    editRowEvent (row) {
      this.$refs.xTable.setActiveRow(row)
    },
    removeRowEvent (row) {
      this.$refs.xTable.remove(row)
    },
    saveRowEvent (row) {
      this.$refs.xTable.clearActived()
    },
    cancelRowEvent (row) {
      const xTable = this.$refs.xTable
      xTable.clearActived().then(() => {
        xTable.revertData(row)
      })
    },
    footerMethod ({ columns, data }) {
      return [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) {
            return '合计'
          }
          if (columnIndex === 1) {
            this.planForm.budget = XEUtils.sum(data.map(row => XEUtils.multiply(row.unitPrice, row.plannedPurchases)))
            return this.planForm.budget
          }
          return ''
        })
      ]
    },
    formatSupplierLabel ({ cellValue, row }) {
      let productId = row.productId
      let productItem = this.supplierListCache.find(item => item.productId === productId)
      if (productItem && productItem.supplierList) {
        let supplierItem = productItem.supplierList.find(item => item.id === cellValue)
        if (supplierItem) {
          return supplierItem.supplierName
        }
      }
      return ''
    },
    updateSupplierList (row) {
      let productId = row.productId
      let item = this.supplierListCache.find(item => item.productId === productId)
      if (item) {
        this.supplierList = item.supplierList
      } else {
        selectSupplierByProductId(row.productId).then(res => {
          this.supplierList = res
          this.supplierListCache.push({
            productId: productId,
            supplierList: res
          })
        })
      }
    },
    editActivedEvent ({ row }) {
      if (row.productId) {
        this.updateSupplierList(row)
      }
    },
    productChange ({ row }) {
      if (row.productId) {
        let product = this.productList.find(item => item.id === row.productId)
        row.productName = product.name
        row.spec = product.spec
        row.typeName = product.typeName
        row.unit = product.unit
        row.unitPrice = product.unitPrice
        row.technicalIndex = product.technicalIndex
        row.inventory = product.inventoryQuantity
        row.supplierId = ''
        this.updateSupplierList(row)
      } else {
        row.productName = ''
        row.spec = ''
        row.typeName = ''
        row.unit = ''
        row.unitPrice = ''
        row.technicalIndex = ''
        row.inventory = ''
        this.supplierList = []
      }
    },
    supplierChange ({ row }) {
      if (row.supplierId) {
        let supplier = this.supplierList.find(item => item.id === row.supplierId)
        row.supplierName = supplier.supplierName
      } else {
        row.supplierName = ''
      }
    }
  }
}
</script>

<style>
#plan .el-dialog__body {
  margin: 0px 0px 20px 20px;
}
</style>
