import axios from '@/common/axios'
import qs from 'qs'

export function addSupplier (data) {
  return axios({
    method: 'post',
    url: '/supplier/add',
    data: qs.stringify(data)
  })
}

export function deleteSupplier (id) {
  return axios({
    method: 'delete',
    url: '/supplier/delete/' + id
  })
}

export function updateSupplierPurchase (data) {
  return axios({
    method: 'put',
    url: '/supplier/purchase/update',
    data: qs.stringify(data)
  })
}

export function updateSupplierQualityFirst (data) {
  return axios({
    method: 'put',
    url: '/supplier/first/quality/update',
    data: qs.stringify(data)
  })
}

export function updateSupplierProduct (data) {
  return axios({
    method: 'put',
    url: '/supplier/product/update',
    data: qs.stringify(data)
  })
}

export function updateSupplierTechnology (data) {
  return axios({
    method: 'put',
    url: '/supplier/technology/update',
    data: qs.stringify(data)
  })
}

export function updateSupplierQualityBatch (data) {
  return axios({
    method: 'put',
    url: '/supplier/batch/quality/update',
    data: qs.stringify(data)
  })
}

export function updateSupplierManagement (data) {
  return axios({
    method: 'put',
    url: '/supplier/management/update',
    data: qs.stringify(data)
  })
}

export function updateSupplierGeneral (data) {
  return axios({
    method: 'put',
    url: '/supplier/general/update',
    data: qs.stringify(data)
  })
}

export function selectSupplierInfo (id) {
  return axios({
    method: 'get',
    url: '/supplier/info/' + id
  })
}

export function selectSupplierList (query) {
  return axios({
    method: 'get',
    url: '/supplier/list',
    params: query
  })
}

export function selectSupplierByProductId (productId) {
  return axios({
    method: 'get',
    url: '/supplier/selectByProductId/' + productId
  })
}
